<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.moments.details')" class="event settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0 moment">
        <CCardBody class="has_tabs pt-0 pb-0">
          <CRow v-if="momentLoading === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>          
          <CRow v-if="momentLoading === false">
            <CCol cols="4" xl="4" lg="4" md="12" sm="12" class="p-0 moment_meta">
              <!-- Moment Name -->
              <CRow class="m-0 mb-2">
                <CCol cols="12" xl="12" class="pb-0">
                  <span class="name">{{$t('sm.Spark')}}{{momentLoading === false && moment.name ? ': ' + moment.name : ''}}</span>
                </CCol>         
              </CRow>
              <!-- Loyalty Points -->
              <CRow v-if="moment.points_granted === 1" class="m-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <span><b>{{$t('loyalty.Coins_granted')}}</b></span>
                  <div class="d-flex mt-1">
                    <div class="d-flex align-items-center currency">
                      <img v-if="momentPointsCurrency === 'points'" :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                      <i v-if="momentPointsCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>
                      <span class="ml-1 points">{{momentPointsCurrency === 'points' ? moment.points : formatToCurrency(moment.points)}}</span>
                    </div>
                    <!-- <div @click="switchMomentPointsCurrency()" class="ml-1 d-flex align-items-center pointer">
                      <i class="fa-solid fa-repeat"></i>
                    </div> -->
                  </div>
                </CCol>
              </CRow> 
              <!-- Sent on -->
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <span><b>{{moment.sent ? $t('sm.Spark_has_been_sent') : $t('sm.Spark_will_be_sent_on')}}</b></span>
                  <div class="mt-1">
                    <span v-if="moment.sent">{{moment.sent | moment("dddd DD-MM-YYYY HH:mm")}}</span>
                    <span v-else-if="moment.moment_scheduled" class="date">{{moment.scheduled_for | moment("dddd DD-MM-YYYY HH:mm")}}</span>                                    
                  </div>
                </CCol>
              </CRow>
              <!-- Recipients-->
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <span><b>{{$t('common.Recipients')}}</b></span>
                  <div class="d-flex align-items-center mt-2 moment_recipients">
                    <div v-for="(recipient, index) in moment.recipients.slice(0, 10)" :key="index" class="recipient">
                      <div v-if="recipient.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + recipient.user_id_external + '/' + recipient.profile_image + '/' + clientToken + ')' }"></div>
                      <div v-else class="profile_icon">
                        <userProfileAvatar :username="recipient.name" :size="40"/>
                      </div>
                    </div>                                        
                    <div v-if="moment.recipients.length > 10" class="ml-1">
                      <span><b>{{moment.recipients.length - 10}}+</b></span>
                    </div>
                    <div @click="activeTab = 1;" class="ml-1 pointer">
                      <span>{{$t('common.Details')}}</span>
                    </div>
                  </div>
                </CCol>
              </CRow>               
              <!-- Smart Filters -->
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <span><b>{{$t('core.Smart_filters_used')}} ({{moment.total_filters}})</b></span>
                  <div class="item_tags">
                    <b-tag v-for="(filter, index) in moment.filters" v-bind:key="index" class="mt-1 detail">
                      <span v-if="filtersWithValueLabel.includes(filter.group_filter_type_tag)">
                        <b>{{$t('filter.' + filter.group_filter_type_tag)}}</b> {{$t('condition.' + filter.group_filter_condition_tag).toLowerCase()}} <b>{{filter.group_filter_value_label}}</b>
                      </span>
                      <span v-else-if="filtersWithFilterValue.includes(filter.group_filter_type_tag)">
                        <b>{{$t('filter.' + filter.group_filter_type_tag)}}</b> {{$t('condition.' + filter.group_filter_condition_tag).toLowerCase()}} <b>{{$t('filter_value.' + filter.group_filter_type_value_tag).toLowerCase()}}</b>
                      </span>                      
                      <span v-else>
                        <b>{{$t('filter.' + filter.group_filter_type_tag)}}</b> {{$t('condition.' + filter.group_filter_condition_tag).toLowerCase()}} <b>{{filter.group_filter_value}}</b>
                      </span>
                    </b-tag>
                  </div>
                </CCol>
              </CRow>             
            </CCol>
            <CCol cols="8" xl="8" lg="8" md="12" sm="12" class="p-0">
              <b-tabs class="no_borders mb-0" v-bind:class="{'no_padding' : activeTab == 1}" type="is-boxed" :animated="false" v-model="activeTab">
                <b-tab-item>
                  <template slot="header">
                    <span>{{ $t('loyalty.Preview_experience') }}</span>
                  </template>
                  <template>
                    <appPreview :ref="'appPreview'" :environmentName="companyData.environment_name" :environmentTag="environmentTag" :previewData="companyData" previewMode="loyalty_moment" :previewContentData="momentEmailPreview"/>
                  </template>
                </b-tab-item>                
                <b-tab-item>
                  <template slot="header">
                    <span>{{$t('common.Recipients')}} ({{moment.total_recipients}})</span>
                  </template>
                  <template>
                    <CRow class="m-0">
                      <CCol cols="12" lg="12" v-bind:class="{ 'pb-0' : listViewEnabled === false || isPaginated === false }">                      
                        <div class="d-flex align-items-center" v-bind:class="{ 'mb-2_5' : listViewEnabled === true }">
                          <b-switch v-if="moment.total_recipients > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
                        </div>
                        <div v-if="listViewEnabled">
                          <CRow>
                            <CCol cols="12" sm="12" md="12" lg="12" class="p-0">
                              <b-table ref="groupTable" class="data_table" :data="moment.recipients" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                                <template slot-scope="props">
                                  <b-table-column field="name" :label="$t('common.Name')">
                                    <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                                      <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                                      <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                                        <userProfileAvatar :username="props.row.name" :size="40"/>
                                      </div>
                                      <b>{{props.row.name}}</b>
                                    </div>
                                  </b-table-column>
                                  <b-table-column field="department_name" :label="$t('common.Department')">
                                    <span>{{props.row.department_name}}</span>
                                  </b-table-column>                        
                                  <b-table-column field="team_name" :label="$t('common.Team')">
                                    <span>{{props.row.team_name}}</span>
                                  </b-table-column>                        
                                </template>
                              </b-table>
                            </CCol>
                          </CRow>
                        </div>
                        <div v-else>
                          <CRow class="tile-container">
                            <CCol cols="3" xl="3" lg="4" md="4" sm="6" v-for="user in currentPageUsers[currentHeadcountPage - 1]" v-bind:key="user.user_id_external" class="p-0">
                              <userProfileCard :userData="user" @click.native="openSidebarRight('user_profile', { user_id_external:  user.user_id_external });" class="pointer"/>
                            </CCol>
                          </CRow>
                          <hr v-if="moment.recipients.length > usersPerPage" class="m-0">
                          <CRow v-if="moment.recipients.length > usersPerPage">
                            <CCol cols="12" md="12">
                              <v-pagination class="justify-content-end" v-model="currentHeadcountPage" :length="userPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>
                  </template>
                </b-tab-item>
              </b-tabs>     
            </CCol>                      
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfileCard from "@/components/common/userProfileCard.vue";
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";
import appPreview from '@/components/common/appPreview.vue';

export default {
  name: 'Moment',
  components: {
    loadingSpinner,
    noPermission,
    userProfileCard,
    userProfileAvatar,
    appPreview
  },
  watch: { 
    '$route.params.id': {
      handler() {
        this.momentIdExternal = this.$route.params.id;
        this.getMomentDetails();        
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      momentIdExternal: null,
      moment: {
        recipients: []
      },
      momentLoading: false,
      momentLoaded: false,
      momentPointsCurrency: 'points',      
      momentEmailPreview: null,
      filtersWithValueLabel: ['user', 'department', 'team', 'target_group'],
      filtersWithFilterValue: ['date_started', 'date_left'],
      companyDetailsLoaded: false,
      companyData: {},
      activeTab: 0,      
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      currentHeadcountPage: 1,
      currentPageUsers: {},
      usersPerPage: 20,
      userPages: 0
    }
  },
  methods: {
    getMomentDetails() {
      // Start the loader
      this.momentLoading = true;
      // Get the event data
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment/' + this.momentIdExternal)
      .then(res => {
        this.moment = res.data.data;
        // Set the email preview
        this.momentEmailPreview = this.moment.html;
        this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$points}", this.moment.points);
        this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$copyright}", "&#169;" + new Date().getFullYear());
        // Get the company details
        this.getCompanyDetails();
        // Enable the pagination if necessary
        (this.moment.total_recipients > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Set the pagination
        this.setPagination();        
        // Stop the loader
        this.momentLoading = false;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },   
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        let companyData = res.data.data;
        // Set the companyPaymentStatus value
        this.companyPaymentStatus = companyData.payment_status;
        // Check if the company environment has been set
        if(companyData.details.environment_tag) this.companyData.environment_tag = companyData.details.environment_tag;
        // Check if the company environment has been set
        if(companyData.details.environment_name) this.companyData.environment_name = companyData.details.environment_name;               
        // Check if the company name has been set
        if(companyData.details.company_name) this.companyData.company_name = companyData.details.company_name;
        // Check if the brand name has been set
        (companyData.details.brand_name) ? this.companyData.brand_name = companyData.details.brand_name : this.companyData.brand_name = companyData.details.company_name;
        // Check if the email sender name has been set
        if(companyData.details.email_signatory) this.companyData.email_signatory = companyData.details.email_signatory;
        // Check if the company image has been set
        if(companyData.look_and_feel.company_image_id) this.companyData.company_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.company_image_id + '.png' + '/' + this.clientToken
        // Check if the company header image has been set
        if(companyData.look_and_feel.header_image_id) this.companyData.header_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.header_image_id + '.png' + '/' + this.clientToken
        // Check if the app background has been set
        if(companyData.look_and_feel.app_background_image_id) this.companyData.app_background_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.app_background_image_id + '.png' + '/' + this.clientToken;
        // Set the primary color
        (companyData.look_and_feel.primary_color) ? this.companyData.primary_color = companyData.look_and_feel.primary_color : this.companyData.primary_color = companyData.details.environment_primary_color;
        // Set the secondary color
        (companyData.look_and_feel.secondary_color) ? this.companyData.secondary_color = companyData.look_and_feel.secondary_color : this.companyData.secondary_color = companyData.details.environment_secondary_color;        
        // Set the default company image
        this.companyData.default_company_image_url = this.cdnBaseUrl + '/base/dashboard/logo/' + companyData.details.environment_tag + '_logo.png';
        // Set the default social wall image
        this.companyData.default_social_wall_image_url = this.cdnBaseUrl + '/base/dashboard/default-socialwall.jpg';
        // Update the companyDetailsLoaded value
        this.companyDetailsLoaded = true;

        if(this.momentEmailPreview) {
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$platform_name}", this.companyData.brand_name);
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$email_signatory}", this.companyData.email_signatory);
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$name}", this.companyData.company_name);
        }        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setPagination() {
      // Set the pagination to the first page
      this.currentHeadcountPage = 1;
      // Reset the userPages value
      this.userPages = 0;
      // Get the headcountDetails
      let headcountDetails = this.moment.recipients;
      // Loop througb the headcountDetails to divide the data in pages         
      for (let i = 0; i < headcountDetails.length; i = i + this.usersPerPage) {
        this.currentPageUsers[this.userPages] = headcountDetails.slice(i, i + this.usersPerPage);
        this.userPages++;
      }
    },    
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format((points/10));
    },
    switchMomentPointsCurrency() {
      (this.momentPointsCurrency === 'points') ? this.momentPointsCurrency = 'euro' : this.momentPointsCurrency = 'points';
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }   
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    
    this.checkModules();
    this.getPlatformPermissions();    
  } 
}
</script>
